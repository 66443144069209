import Footer from "../components/footer";
import Navbar from "../components/navbar";

const DesignAndLayout = () => {
  return (
    <div>
      <Navbar />
      <section className="container mx-auto">
        <div
          className="bg-cover bg-center h-[400px] relative hero-space-top"
          style={{ backgroundImage: "url('../images/services/design.jpg')" }}
        >
          <div className="h-fit w-5/6 mx-auto md:w-[390px] md:absolute md:top-[60px] md:right-[60px] bg-[#7CC143] p-[30px]">
            <h1 className="text-white text-[35px] mb-4">
              <strong>
                DESIGN & <br />
              </strong>
              LAYOUTS
            </h1>
            <a href="#design-examples" className="bg-primary p-4 text-white ">
              DISCOVER MORE
            </a>
          </div>
        </div>
      </section>
      <section className="bg-[#f7f7f4] container mx-auto px-10">
        <div className="">
          <div className="flex flex-col md:flex-row gap-10 py-10 px-4">
            <div className="basis-1/2 grid grid-flow-row  md:grid-flow-col grid-rows-3 md:grid-rows-2 gap-y-1 gap-x-4">
                <img src="/images/services/flower-bed.jpg" alt=""  className="w-full md:col-span-2 md:h-5/6"/>
                <img src="/images/services/d-2.jpg" alt="Garden Retreat" className="w-full object-contain" />
                <img src="/images/services/d-1.jpg" alt="Suitable Landscape Design" className="w-full object-contain" />
            </div>
            <div className="basis-1/2 space-y-8">
              <p className="text-base">
              Design and layout services within landscaping are essential components in creating visually stunning and functional outdoor spaces. These services involve the careful planning, conceptualization, and visualization of landscape designs that harmonize with the existing environment and meet the specific needs and preferences of the clients. With the expertise of professional landscape designers, the design and layout phase sets the foundation for a successful landscaping project.
              </p>
              <ol className="px-4 md:px-0 list-none">
                <li className="mb-4"><strong>Personalized and Tailored Designs:</strong> Design and layout services allow clients to have a customized landscape that reflects their unique style, preferences, and requirements. Professional landscape designers work closely with clients to understand their vision, incorporating elements such as plant selections, hardscapes, focal points, and color schemes to create a landscape design that truly represents their personality and lifestyle.</li>
                <li className="mb-4"><strong>Functional and Efficient Use of Space:</strong> Effective design and layout ensure that outdoor spaces are optimized for their intended purposes. Whether it's creating designated areas for entertaining, gardening, or relaxation, professional landscape designers have the expertise to maximize the functionality of the available space. They consider factors such as traffic flow, accessibility, and spatial organization to create a layout that is both practical and aesthetically pleasing.</li>
                <li className="mb-4"><strong>Enhanced Visual Appeal:</strong> The artistic vision of landscape designers plays a crucial role in creating visually appealing outdoor environments. They skillfully combine various elements, including plants, hardscapes, lighting, and focal points, to create a cohesive and visually striking landscape design. The careful selection and arrangement of plants, colors, textures, and materials result in a landscape that captivates the eye and creates a lasting impression.</li>
                <li className="mb-4"><strong>Integration with the Surrounding Environment:</strong> Design and layout services ensure that the landscape design seamlessly integrates with the existing natural surroundings. Professional landscape designers consider the topography, existing vegetation, and architectural features of the site to create a design that complements and enhances the overall environment. This integration creates a sense of harmony and balance, making the landscape appear as a natural extension of the surrounding area.</li>
                <li><strong>Practical Considerations:</strong> Landscape designers take into account practical considerations such as drainage, irrigation systems, and sustainability practices during the design and layout phase. They incorporate efficient irrigation methods, water-saving techniques, and eco-friendly materials to create environmentally responsible landscapes that require minimal maintenance and upkeep.</li>
              </ol>

              <p>The benefits of design and layout services in landscaping are numerous and contribute to the overall success and satisfaction of the project. Let's explore some key advantages:</p>
            </div>

          </div>
          <div className="flex flex-col md:flex-row gap-10 py-10 px-4" id="design-examples">
            <div className="basis-1/2 grid grid-flow-row  md:grid-flow-col grid-rows-3 md:grid-rows-2 gap-y-2 gap-x-4">
                <img src="/images/services/design.jpg" alt=""  className="w-full md:col-span-2"/>
                <img src="/images/services/d-1.jpg" alt="Garden Retreat" className="w-full object-contain" />
                <img src="/images/services/d-2.jpg" alt="Suitable Landscape Design" className="w-full object-contain" />
            </div>
            <div className="basis-1/2 space-y-8">
              <p className="text-base">Examples of design and layout services within landscaping encompass a wide range of projects and styles. Here are a few illustrations:</p>
              <ol className="list-none px-4 md:px-0">
                <li className="mb-4"><strong>Formal Garden Design:</strong> Creating a formal garden with geometric patterns, symmetrical plantings, and well-defined pathways, exuding elegance and sophistication.</li>
                <li className="mb-4"><strong>Sustainable Landscape Design:</strong> Developing a landscape design that incorporates native plants, rainwater harvesting systems, and permeable paving, promoting water conservation and environmental sustainability.</li>
                <li className="mb-4"><strong>Modern Landscape Design:</strong> Designing a sleek and minimalist landscape with clean lines, contemporary materials, and innovative features such as outdoor art installations or fire features.</li>
                <li className="mb-4"><strong>Cottage Garden Design:</strong> Crafting a charming and romantic garden with an abundance of colorful flowers, meandering pathways, and cozy seating areas, evoking a sense of nostalgia and tranquility.</li>
                <li><strong>Xeriscape Design:</strong> Creating a water-efficient landscape design that utilizes drought-tolerant plants, gravel or mulch pathways, and efficient irrigation systems, minimizing water usage while maintaining a beautiful and sustainable outdoor space.</li>
              </ol>
              <p className="text-base">These examples demonstrate the versatility and creativity involved in design and layout services within landscaping. By engaging professional landscape designers, clients can benefit from their expertise, artistic vision, and technical knowledge to transform their outdoor spaces into captivating and functional landscapes that surpass their expectations.</p>
            </div>

          </div>

        </div>
      </section>
      <Footer />
    </div>
  );
};
export default DesignAndLayout;

import Navbar from "../components/navbar";
import Footer from "../components/footer";

const LandscapServices = () => {
  return (
    <div>
      <Navbar />
      <div className="container mx-auto">
        <div
          className="bg-cover bg-center h-[400px] relative hero-space-top"
          style={{ backgroundImage: "url('../images/home/landscape.jpg')" }}
        >

        </div>
        <div className="py-10 px-4 md:px-10 bg-[#f7f7f4]">
          <p className="text-base">
          Within our landscaping services, our mission is to transform our clients' barren
           spaces into vibrant, green gardens that reflect their unique personalities and
           lifestyles. We offer comprehensive garden landscape solutions, including repairing,
           redesigning, and remodeling existing gardens. By surpassing expectations, our aim is to
           create a new garden that not only fulfills aesthetic desires but also serves as a tranquil
           retreat, providing a soothing environment that helps alleviate the stresses of daily life.
          </p>
        </div>
        <div className="pt-10 " id="softscape-section">
            <div>
            <div className="flex flex-col md:flex-row gap-6 px-6 md:px-16 ">
            <div className="basis-1/2 space-y-8" >
              <h1 className="text-[36px]">SOFTSCAPE</h1>
              <p>
              By incorporating these softscape elements, we can transform your garden landscape into a
              harmonious and visually captivating outdoor space that perfectly complements your preferences and lifestylew. Some of the features available at Savio include:
              </p>
              <ul className="list-outside list-disc">
                <li><span className="font-bold">Flower Beds:</span> Adding flower beds with a variety of colorful flowers creates a visually appealing focal point in the garden</li>
                <li><span className="font-bold">Shrubbery:</span> Utilizing different types of shrubs adds structure, texture, and depth to the garden</li>
                <li><span className="font-bold">Trees:</span> Strategically placing trees within the garden provides shade, privacy, and vertical interest</li>
                <li><span className="font-bold">Ground Covers:</span> Ground covers serve as low-growing plants that spread and cover bare soil, adding visual interest and reducing maintenance needs</li>
                <li><span className="font-bold">Climbing Vines:</span> Adding climbing vines to walls, trellises, or arbors can create a stunning vertical element in the garden</li>
              </ul>
            </div>
            <div className="basis-1/2">
                <img src="/images/services/garden.jpg" alt="" className="w-full h-full" />

            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 md:px-16 gap-4 md:gap-10 gap-10 py-10">
            <div>
                <img src="/images/services/flower-bed.jpg" alt="" />
                <p>Flower Beds</p>
            </div>
            <div>
                <img src="/images/services/shrubbs.jpg" alt="" />
                <p>Shrubbery</p>
            </div>
            <div>
                <img src="/images/services/trees.jpg" alt="" />
                <p>Trees</p>
            </div>
            <div>
                <img src="/images/services/ground-covering.jpg" alt="" />
                <p>Ground Covers</p>
            </div>
            <div>
                <img src="/images/services/climbing-trees.jpg" alt="" />
                <p>Climbing Vines</p>
            </div>
          </div>
            </div>
            <div className="bg-[#f7f7f4] pt-16">
            <div className="flex flex-col md:flex-row gap-6 px-6 md:px-16">
            <div className="basis-1/2">
                <img src="/images/home/ol.jpg" alt="" className="w-full h-full" />

            </div>
            <div className="basis-1/2 space-y-8">
              <h1 className="text-[36px]">HARDSCAPE</h1>
              <p>
                By integrating these hardscape features alongside the soft garden
                landscaping elements, we create a harmonious and balanced outdoor
                space that combines the beauty of nature with the functionality and
                durability of non-living elements. It involves utilizing materials such
                as stones, wood, and other non-plant elements to enhance the overall
                aesthetic appeal and functionality of the garden
              </p>
              <ul className="list-outside list-disc">
                <li><span className="font-bold">Paved Pathways:</span> Adding climbing vines to walls, trellises, or arbors can create a stunning vertical element in the garden</li>
                <li><span className="font-bold">Water Features:</span> Incorporating water features such as fountains, ponds, or waterfalls can add a sense of tranquility and create a focal point in the garden</li>
                <li><span className="font-bold">Patio or Deck:</span> Including a patio or deck area provides an outdoor living space for relaxation and entertainment</li>
              </ul>
            </div>

          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 px-6 md:px-16 gap-4 md:gap-10 pt-10">
            {/* hardscape grid */}
            <div className="image-container">
                <img src="/images/services/hardscape-1.jpg" alt="" />
                <p>Water Features</p>
            </div>
            <div className="image-container">
                <img src="/images/services/hardscape-2.jpg" alt="" />
                <p>Paved Pathways</p>
            </div>
            <div className="image-container">
                <img src="/images/services/hardscape-3.jpg" alt="" />
                <p>Patio or Decks</p>
            </div>
          </div>
            </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};
export default LandscapServices;

import "./App.css";
import Navbar from "./components/navbar";
import Hero from "./components/home/hero";
import AboutUsSection from "./components/home/about";
import Recentprojects from "./components/home/projects";
import ShopCategories from "./components/home/shop-categories";
import Footer from "./components/footer";
import DesignAndAnalyse from "./components/home/tabs";

function App() {
  return (
    <div>
      <Navbar></Navbar>
      <Hero></Hero>
      <AboutUsSection />
      <Recentprojects />
      <DesignAndAnalyse />
      <ShopCategories />

      <Footer />
    </div>
  );
}

export default App;

import Footer from "../components/footer";
import Navbar from "../components/navbar";

const LandAndConstruction = () => {
  return (
    <div>
      <Navbar />
      <div className="container mx-auto">
        <div
          className="bg-cover bg-center h-[400px] relative hero-space-top"
          style={{ backgroundImage: "url('../images/home/landscape.jpg')" }}
        >
          <div className="h-fit w-11/12 mx-auto md:w-[390px] md:absolute md:top-[60px] md:right-[60px] bg-[#7CC143] p-[30px]">
            <h1 className="text-white text-2xl md:text-[35px] mb-8">
              <strong>
                LANDSCAPE <br />
              </strong>
              CONSTRUCTION
            </h1>
            <a href="#services-offered" className="bg-primary p-4 text-white ">
              DISCOVER MORE
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between gap-10 py-10 px-4 md:px-16">
          <h1 className="text-[#28a745] text-[36px] basis-1/3">
            <span className="text-[#7cc143] mr-2">ABOUT</span>LANDSCAPE{" "}
            <br></br>
            CONSTRUCTION
          </h1>
          <div className="hidden md:block h-20 w-0.5 bg-[#606060]"></div>
          <p className="basis-1/2">
          Landscaping construction is a specialized field that focuses on the design and implementation of outdoor spaces, transforming them into functional, aesthetically pleasing, and harmonious environments. It involves the skilled integration of various elements such as hardscapes, softscapes, water features, lighting, and more to create cohesive and captivating landscapes. The process of landscaping construction combines creativity, technical expertise, and attention to detail to bring the client's vision to life..
          </p>
        </div>
      </div>
      <section className="bg-[#f7f7f4] container-fluid md:py-16" id="services-offered">
        <div className="container mx-auto">
          <div className="px-4 md:px-16 mb-4 ">
            <h1 className="text-[#28a745] text-[36px]">
              The Service <br /> we Offer
            </h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-16 px-4 md:px-16" >
            <div className="flex justify-between">
              <div className="hidden md:block w-0.5 h-full bg-[#606060]"></div>
              <ul className="space-y-6">
                <li>Site Preparation</li>
                <li>Hardscape Installation</li>
                <li>Softscape Installation</li>
              </ul>
            </div>
            <div className="flex justify-between">
              <div className="hidden md:block w-0.5 h-full bg-[#606060]"></div>
              <ul className="space-y-6">
                <li>Irrigation System Installation </li>
                <li> Lighting Installation </li>
                <li>  Water Features </li>
              </ul>
            </div>
            <div className="flex justify-between">
              <div className="hidden md:block w-0.5 h-full bg-[#606060]"></div>
              <ul className="space-y-6">
                <li>Outdoor living spaces</li>
                <li>Sustainable and Eco-friendly practices</li>
                <li>Landscape Drainage Systems</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="container mx-auto">
        <div className="flex flex-col md:flex-row gap-8 px-4 md:px-0 md:pt-8">
          <div className=" basis-1/2">
            <h1 className="text-[#28a745] text-[36px]">
            The Benefits Of Landscaping Construction <br />
            </h1>
            <ul className="list-disc list-inside ">
              <li className="mb-2">Enhanced Outdoor Spaces</li>
              <li className="mb-2">Increased Property Value</li>
              <li className="mb-2">Personalized Design</li>
            </ul>

          </div>
          <div className="basis-1/2 land-construction-img-container" id="benefits-img-section">
            <img
              src="/images/services/benefits-of-Landscaping-Construction section 1.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-8 py-10 px-4 md:px-0">
          <div className="basis-1/2 land-construction-img-container">
            <img
              src="/images/services/increased-property-value.jpeg"
              alt=""
            />
          </div>
          <div className="space-y-10 basis-1/2">
            <h1 className="text-[#28a745] text-[36px]">
            Increased Property Value
            </h1>
            <p>
            A well-designed and professionally executed landscape
            significantly enhances the value of your property. Landscaping
             construction adds curb appeal and makes a strong impression,
             increasing the desirability of your home or commercial space. It is an investment that offers a high return, often surpassing the initial cost.
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-8 py-10 px-4 md:px-0">
          <div className="space-y-10 basis-1/2">
            <h1 className="text-[#28a745] text-[36px]">
              Personalized Design
            </h1>
            <p>
            One of the key advantages of landscaping construction is the
            opportunity to customize your outdoor space according to your
            preferences, lifestyle, and specific needs. Professional landscapers work closely with clients to understand their vision, incorporating unique features, materials,
            and plant selections that reflect individual tastes and style
            </p>
          </div>
          <div className="basis-1/2 land-construction-img-container">
            <img
              src="/images/services/personalized-design.jpg"
              alt=""
             
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-8 py-10 px-4 md:px-0">
          <div className="space-y-10 basis-1/2">
            <h1 className="text-[#28a745] text-[36px]">
            Examples of landscaping construction
            </h1>
            <ol className="list-disc">
              <li className="mb-4">
                <span className="font-bold break-words">Outdoor Entertainment Area:</span><br />Construction of a spacious patio or deck with integrated seating, an outdoor kitchen, and a fire pit, creating a perfect space for hosting gatherings and enjoying outdoor activities.
              </li>
              <li className="mb-4">
                <span className="font-bold break-words">Water Feature Installation:</span><br />Designing and constructing a serene water feature such as a pond, waterfall, or fountain, adding visual appeal and the calming sound of running water to the landscape.
              </li>
              <li className="mb-4">
                <span className="font-bold break-words">Garden Retreat:</span><br />Creating a secluded garden retreat complete with pathways, lush plantings, and a pergola or gazebo, providing a private sanctuary for relaxation and meditation.
              </li>
              <li className="mb-4">
                <span className="font-bold break-words">Sustainable Landscape Design:</span><br />Implementing environmentally friendly features like rain gardens, green roofs, or native plantings, promoting water conservation, reducing energy consumption, and attracting local wildlife.
              </li>
              <li className="mb-4">
                <span className="font-bold break-words">Poolside Oasis:</span><br />Designing and constructing a luxurious poolside landscape with elegant paving, comfortable seating areas, and vibrant plantings, transforming a regular pool into a resort-like retreat.
              </li>
            </ol>

            <p>
            These examples illustrate the versatility and creativity involved in landscaping construction, demonstrating the endless possibilities
            for crafting unique and captivating outdoor spaces that blend seamlessly with the natural surroundings.
            </p>
          </div>
          <div className="basis-1/2">
          <img
                src="/images/services/c-1.jpg"
                alt=""
                className="w-full object-contain h-[80%]"
              />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default LandAndConstruction;

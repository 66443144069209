import Footer from "../components/footer";
import Navbar from "../components/navbar";
import SharedHero from "../components/shared-hero";
import background from "../backgrounds/services.jpg";

const ServicesPage = () => {
  return (
    <div>
      <Navbar />
      <SharedHero
        title={" Our Landscape Design Services"}
        background={background}
      />
      <div className="container mx-auto  botton-content">
        <div className="px-2 md:px-28 py-10 text-center space-y-10 bg-[#E1E2E6]">
          <h3>
            What type of landscaping or gardening service are you looking for?
          </h3>
          <div className="flex flex-col md:flex-row items-center justify-center gap-10">
            <a href="/landscaping-services"><button>Landscaping Services (hardscape)</button></a>
            <a href="#gardening"><button>Gardening and Maintenance (softscape) </button></a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-10 my-10 services px-3">
            <div className="basis-1/2">
            <img src="/images/home/project.jpg" alt=""  className="h-full object-cover"/>
            </div>

          <div className="basis-1/2 space-y-3">
            <h3>Landscaping Service</h3>
            <p>
              Good Landscaping is a fusion of beauty and functionality. We focus
              on harmonising your yard and your house for you to experience and
              enjoy quality time with your family. <br /> <br /> Landscaping is a large
              undertaking, which requires the support and assistance of a team
              with considerable experience. Our team takes the time to learn
              what you need, so that they can design a landscape or garden that
              suits your specific needs, lifestyle, and family. <br /> <br /> We follow the
              local regulations in regards to removing any indigenous trees and
              shrubs from the property. We first plan the project and then
              manage it within the planned budget and timeline, allowing you to
              just sit back and enjoy your new space and backyard ideas being
              realized.Once the council permits are obtained and we are able to
              safely remove the unwanted vegetation from the environment, our
              services will begin. <br /> <br /> We provide house owners seeking our
              assistance with well-researched plans for developing beautiful
              areas based on our vast experience in managing and developing
              gardens. We offer all landscaping services through our dedicated
              and expert team. We promise to create an aesthetic and a practical
              space with your ideas and our collaboration. If you know you need
              to do something new with your garden, start the conversation today
              and see how we can bring your imagination to life.
            </p>
            <a href="/contact"><button>GET A QUOTE TODAY</button></a>
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row gap-10 my-10 services px-3">
          <div className="basis-1/2 space-y-4" id="gardening">
            <h3>Gardening and Maintenance Services</h3>
            <p>
              Good Landscaping is a fusion of beauty and functionality. We focus
              on harmonising your yard and your house for you to experience and
              enjoy quality time with your family. <br /> <br /> Landscaping is a large
              undertaking, which requires the support and assistance of a team
              with considerable experience. Our team takes the time to learn
              what you need, so that they can design a landscape or garden that
              suits your specific needs, lifestyle, and family. <br /> <br /> We follow the
              local regulations in regards to removing any indigenous trees and
              shrubs from the property. We first plan the project and then
              manage it within the planned budget and timeline, allowing you to
              just sit back and enjoy your new space and backyard ideas being
              realized.Once the council permits are obtained and we are able to
              safely remove the unwanted vegetation from the environment, our
              services will begin. <br /> <br /> We provide house owners seeking our
              assistance with well-researched plans for developing beautiful
              areas based on our vast experience in managing and developing
              gardens. We offer all landscaping services through our dedicated
              and expert team. We promise to create an aesthetic and a practical
              space with your ideas and our collaboration. If you know you need
              to do something new with your garden, start the conversation today
              and see how we can bring your imagination to life.
            </p>
            <a href="/contact" className="mt-4"><button>GET A QUOTE TODAY</button></a>
          </div>
          <div className="basis-1/2">
            <img src="/images/services/garden.jpg" alt=""  className="h-full object-cover"/>
            </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default ServicesPage;

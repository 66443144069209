
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M15 3l6 9h-4.586l2.293 2.293-1.414 1.414L10.414 8 12.1 6.314 9.707 3.92 8.293 5.334 5.9 2.94 4.485 4.354 3.07 2.94 1.656 4.354 3.342 6.04H3v2h3.243l-2.19 2.19 1.414 1.414L13.586 8l-2.293-2.293 1.414-1.414L15 3z" />
      </svg>
    </button>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <button onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M9 3L3 12h4.586L3.293 14.293l1.414 1.414L13.586 8l-2.293-2.293 1.414-1.414L9 3z" />
        <path d="M15 3l6 9h-4.586l2.293 2.293-1.414 1.414L10.414 8 12.1 6.314 9.707 3.92 8.293 5.334 5.9 2.94 4.485 4.354 3.07 2.94 1.656 4.354 3.342 6.04H3v2h3.243l-2.19 2.19 1.414 1.414L13.586 8l-2.293-2.293 1.414-1.414L15 3z" />
      </svg>
    </button>
  );
};





const Recentprojects = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

  };
  return (


    <section className="container mx-auto recent-projects py-10">
      <Carousel >

      <div className="flex flex-col md:flex-row relative">
        <div className="space-y-6 bg-[#6c6c6c] p-6 basis-1/2">
          <div className="flex justify-between">
          <h3>
            RECENT <strong>PROJECTS</strong>
          </h3>
          <div className="flex gap-4">
          </div>
          </div>
          <p>Revitalizing Landscape</p>
          <p>
          Our team undertook a shrub reduction and removal project to enhance the aesthetics and functionality of the landscape. By carefully pruning and removing select shrubs, we created a more open and inviting outdoor space for our client <span className="italic"> - Cynthia Kitolo</span>
          </p>
        </div>
       <div className="basis-1/2">
       <img src="/images/home/Revitalizing_Landscape.jpg" alt="project"  className="w-full h-full object-cover"/>
       </div>
      </div>
      <div className="flex flex-col md:flex-row relative">
        <div className="space-y-6 bg-[#6c6c6c] p-6 basis-1/2">
          <div className="flex justify-between">
          <h3>
            RECENT <strong>PROJECTS</strong>
          </h3>
          <div className="flex gap-4">
          {/* <i class="fa fa-chevron-left text-white text-[28px]"></i>
          <i class="fa fa-chevron-right text-white text-[28px]"></i> */}
          </div>
          </div>
          <p>Tranquil Garden Transformation</p>
          <p>
            In one of our recent projects, we transformed a tired and lackluster garden into a tranquil oasis. Through strategic plant selection, hardscape installations, and meticulous attention to detail, we created a harmonious balance between natural elements and architectural features, resulting in a serene and captivating outdoor retreat <span className="itallic"> – Peter Mulwa</span>
          </p>
        </div>
       <div className="basis-1/2">
       <img src="/images/home/tranquil_garden_transformation.jpg" alt="project"  className="w-full h-full object-cover"/>
       </div>
      </div>
      <div className="flex flex-col md:flex-row relative">
        <div className="space-y-6 bg-[#6c6c6c] p-6 basis-1/2">
          <div className="flex justify-between">
          <h3>
            RECENT <strong>PROJECTS</strong>
          </h3>
          <div className="flex gap-4">
          {/* <i class="fa fa-chevron-left text-white text-[28px]"></i>
          <i class="fa fa-chevron-right text-white text-[28px]"></i> */}
          </div>
          </div>
          <p>Residential Landscaping Makeover</p>
          <p>
            Our team recently completed a residential landscaping project that involved a complete overhaul of the existing outdoor space. With the installation of new plantings, decorative features, and a well-designed layout, we successfully revitalized the property, creating a visually stunning and functional landscape that the homeowners can enjoy for years to come <span className="italic"> – George Yieke</span>
          </p>
        </div>
       <div className="basis-1/2">
       <img src="/images/home/residential_landscaping_makeover.jpg" alt="project"  className="w-full h-full object-cover"/>
       </div>
      </div>
      <div className="flex flex-col md:flex-row relative">
          <div className="space-y-6 bg-[#6c6c6c] p-6 basis-1/2">
            <div className="flex justify-between">
              <h3>
                RECENT <strong>PROJECTS</strong>
              </h3>
              <div className="flex gap-4">
              {/* <i class="fa fa-chevron-left text-white text-[28px]"></i>
              <i class="fa fa-chevron-right text-white text-[28px]"></i> */}
              </div>
            </div>
            <p>Renovation of Public Garden</p>
            <p>
              To mitigate the impact of pedestrian traffic on shrubs, we supplied and spread 92m2 of 40mm Plum slate chippings in the affected areas. Additionally, damaged shrubs were removed, and 108m2 of bark mulch was applied to all planted areas as needed <span className="italic"> - Wendy Ngunga</span>
            </p>
          </div>
          <div className="basis-1/2">
            <img src="/images/home/renovation_public_garden.jpg" alt="project"  className="w-full h-full object-cover"/>
          </div>
        </div>
    </Carousel>
    </section>
  );
};
export default Recentprojects;

const Footer = () => {
  return (
    <footer className="container mx-auto bg-[#606060]">
      <div class="footer-container ">
        <div class="col one">
          <h4>Savio Landscaping Services</h4>
          <p>
           At Savio Landscaping, where we believe in harnessing the transformative power of nature. Our passion lies in enhancing outdoor spaces to improve the quality of life for individuals, families, and communities alike
          </p>
        </div>
        <div class="col two">
          <h4>Services</h4>
          <ul>
            <li>
              <a href="/land-and-construction-works">Landscape Construction Works </a>
            </li>
            <li>
              <a href="/landscaping-services">Landscaping Services</a>
            </li>
            <li>
              <a href="/garden-and-maintenance">Gardening & Maintenance</a>
            </li>

            <ul className="flex justify-start gap-x-4 text-lg socials-icons">
              <li>
                <a href="https://www.twitter.com" >
                  <img className='h-6 hover:text-white' src="/icons/twitter.svg" alt="Twitter"  />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com">
                  <img className='h-6' src="/icons/instagram.svg" alt="Instagram" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com">
                  <img className='h-6' src="/icons/facebook.svg" alt="Facebook" />
                </a>
              </li>
            </ul>
          </ul>
        </div>
        <div class="col three">
          <h4>Company</h4>
          <ul>
            <li>
              <a href="https://shop.saviolandscaping.com">Shop</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            <li>
              <a href="/projects">Projects</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="bottom-footer ">
        <p className="pb-6">Savio Landscaping Services. Rights Reserved. Copyright 2022.</p>
      </div>
    </footer>
  );
};
export default Footer;




const SharedHero=({title,subtitle,background})=>{
    return (
        <section className="container mx-auto " >
            <div className="h-[400px] w-full flex items-center justify-center shared-hero" style={{backgroundImage:`url(${background})`}}>
                <h1>
               {title}
                </h1>
            </div>

        </section>
    )
}

export default SharedHero
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar = ({toggleOpen}) => {
  const [navitems, setNavItems] = useState([]);
  const [click, setClick] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isServiceItemOpen, setServiceItemOpen] = useState(false);


  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const handleNavClick = () => setClick(!click);
  const handleClick = (index) => {
    if (index == 5) {
      setClick(!click);
    }
  };
  useEffect(() => {}, []);
  return (
    <section class="container mx-auto">
      {/* smaller screens */}
      <header className="md:hidden">
        <div class="flex justify-between items-center">
          <NavLink to={'/'}> <img
              src="/images/favicon/logo.png"
              alt="Logo"
              className="w-24 object-contain h-24 "
          /></NavLink>

          {/* contact Infor */}
          <div className="flex flex-col gap-y-2.5 text-small">
          <div class=" flex">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.0938 26.625C21.9583 26.625 18.9022 25.926 15.9252 24.5279C12.9473 23.1308 10.3101 21.2836 8.01375 18.9862C5.71639 16.6899 3.86917 14.0527 2.47208 11.0748C1.07403 8.09785 0.375 5.04167 0.375 1.90625C0.375 1.46875 0.520833 1.10417 0.8125 0.8125C1.10417 0.520833 1.46875 0.375 1.90625 0.375H7.8125C8.15278 0.375 8.4566 0.484375 8.72396 0.703125C8.99132 0.921875 9.14931 1.20139 9.19792 1.54167L10.1458 6.64583C10.1944 6.98611 10.1886 7.29576 10.1283 7.57479C10.0671 7.85479 9.92708 8.10417 9.70833 8.32292L6.20833 11.8958C7.22917 13.6458 8.50521 15.2865 10.0365 16.8177C11.5677 18.349 13.2569 19.6736 15.1042 20.7917L18.5312 17.3646C18.75 17.1458 19.0358 16.9815 19.3888 16.8717C19.7407 16.7628 20.0868 16.7326 20.4271 16.7812L25.4583 17.8021C25.7986 17.875 26.0781 18.0388 26.2969 18.2935C26.5156 18.5492 26.625 18.8472 26.625 19.1875V25.0938C26.625 25.5313 26.4792 25.8958 26.1875 26.1875C25.8958 26.4792 25.5313 26.625 25.0938 26.625Z"
                    fill="#7CC143"
                  />
                </svg>
              <div class="right pad">
                  <p className="text-[#7CC143] text-right">0712-654-357</p>
              </div>
            </div>

            <div class="flex right pad">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 31 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.83341 24.1667C3.03133 24.1667 2.34494 23.8813 1.77425 23.3106C1.20258 22.739 0.916748 22.0521 0.916748 21.25V3.74999C0.916748 2.94791 1.20258 2.26152 1.77425 1.69083C2.34494 1.11916 3.03133 0.833328 3.83341 0.833328H27.1667C27.9688 0.833328 28.6557 1.11916 29.2274 1.69083C29.7981 2.26152 30.0834 2.94791 30.0834 3.74999V21.25C30.0834 22.0521 29.7981 22.739 29.2274 23.3106C28.6557 23.8813 27.9688 24.1667 27.1667 24.1667H3.83341ZM15.5001 13.9583L27.1667 6.66666V3.74999L15.5001 11.0417L3.83341 3.74999V6.66666L15.5001 13.9583Z"
                    fill="#7CC143"
                  />
                </svg>
                <p className="text-[#7CC143] text-xs">info@saviolandscapingservices.co.ke</p>
              </div>
          </div>
        </div>


      </header>


      {/* header for bigger screens */}
      <header className="hidden md:block">
        <div class="flex justify-between items-center">
         <NavLink to={'/'}> <img
            src="/images/favicon/logo.png"
            alt="Logo"
            className="w-24 object-contain h-24"
          /></NavLink>

          <div class="header-contacts">
            <div class="contact contact-one">
              <div class="left pad">
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.0938 26.625C21.9583 26.625 18.9022 25.926 15.9252 24.5279C12.9473 23.1308 10.3101 21.2836 8.01375 18.9862C5.71639 16.6899 3.86917 14.0527 2.47208 11.0748C1.07403 8.09785 0.375 5.04167 0.375 1.90625C0.375 1.46875 0.520833 1.10417 0.8125 0.8125C1.10417 0.520833 1.46875 0.375 1.90625 0.375H7.8125C8.15278 0.375 8.4566 0.484375 8.72396 0.703125C8.99132 0.921875 9.14931 1.20139 9.19792 1.54167L10.1458 6.64583C10.1944 6.98611 10.1886 7.29576 10.1283 7.57479C10.0671 7.85479 9.92708 8.10417 9.70833 8.32292L6.20833 11.8958C7.22917 13.6458 8.50521 15.2865 10.0365 16.8177C11.5677 18.349 13.2569 19.6736 15.1042 20.7917L18.5312 17.3646C18.75 17.1458 19.0358 16.9815 19.3888 16.8717C19.7407 16.7628 20.0868 16.7326 20.4271 16.7812L25.4583 17.8021C25.7986 17.875 26.0781 18.0388 26.2969 18.2935C26.5156 18.5492 26.625 18.8472 26.625 19.1875V25.0938C26.625 25.5313 26.4792 25.8958 26.1875 26.1875C25.8958 26.4792 25.5313 26.625 25.0938 26.625Z"
                    fill="#7CC143"
                  />
                </svg>
              </div>
              <div class="right pad">
                <p>0712-654-357</p>
              </div>
            </div>
            <div class="contact contact-one">
              <div class="left pad">
                <svg
                  width="31"
                  height="25"
                  viewBox="0 0 31 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.83341 24.1667C3.03133 24.1667 2.34494 23.8813 1.77425 23.3106C1.20258 22.739 0.916748 22.0521 0.916748 21.25V3.74999C0.916748 2.94791 1.20258 2.26152 1.77425 1.69083C2.34494 1.11916 3.03133 0.833328 3.83341 0.833328H27.1667C27.9688 0.833328 28.6557 1.11916 29.2274 1.69083C29.7981 2.26152 30.0834 2.94791 30.0834 3.74999V21.25C30.0834 22.0521 29.7981 22.739 29.2274 23.3106C28.6557 23.8813 27.9688 24.1667 27.1667 24.1667H3.83341ZM15.5001 13.9583L27.1667 6.66666V3.74999L15.5001 11.0417L3.83341 3.74999V6.66666L15.5001 13.9583Z"
                    fill="#7CC143"
                  />
                </svg>
              </div>
              <div class="right pad">
                <p>
info@saviolandscapingservices.co.ke</p>
              </div>
            </div>
            <div className="h-10 w-[1px] bg-[#606060] mx-2"></div>
            <div class="contact contact-one">
              <div class="right pad">
               <NavLink to={'/'}> <p>Home</p></NavLink>
              </div>
            </div>
            <div class="contact contact-one">
              <div class="right pad">
               <NavLink to={'/services'}> <p>Services</p></NavLink>
              </div>
            </div>
            <div class="contact contact-one">
              <div class="right pad">
              <NavLink to={'/projects'}> <p>Projects</p></NavLink>
              </div>
            </div>
            <div class="contact contact-one">
              <div class="right pad">
                <NavLink to={'/contact'}> <p>Contact Us</p></NavLink>
              </div>
            </div>
            <div class="contact contact-one">
             <a href="https://shop.saviolandscaping.com"  rel="noopener noreferrer" >
             <button className="flex items-center py-2 px-10 bg-[#0D7438] text-white gap-2">
              <i class="fa fa-shopping-bag" aria-hidden="true"></i>
                Shop
              </button>
             </a>
            </div>
          </div>
        </div>
      </header>
      <nav>
        {/* mobile screen */}
        <div className="bg-[#606060] pl-2 py-3 md:hidden">
          <button className="bg-[#7CC143] shadow-md border-4 px-5 py-2 rounded-md text-white"
                onClick={() => {
                      setServiceItemOpen(!isServiceItemOpen)
                    }
          }>
                  {/* <i class="fa fa-bars" aria-hidden="true"></i> */}

            {!isServiceItemOpen ? 'Menu' :<i class="fa fa-times" aria-hidden="true"></i>}
          </button>

          {isServiceItemOpen ? (
            <div className="flex flex-col links px-2" id="links">
              <a href="/landscaping-services" class="active">
                Landscaping
                Services
              </a>
              <a href="/garden-and-maintenance">Gardening &  Maintenance </a>
              <a href="/land-and-construction-works"> Landscape Construction Works</a>
              <a href="/design-and-layout">Design &  Layout</a>
              <a href="/arboriculture">Arboriculture </a>
              <a href="/projects">Projects </a>
              <a href="/services">Services </a>
              <a href="/contact">Contact Us </a>
            </div>
            ):<></>}


        </div>
        {/* desktop tablet screen */}
        <div className="nav-container bg-[#606060]">
          <div className="nav logo">
            <a href="index.html" class="nav-logo">
              <img
                src="images/favicon/logo.png"
                alt="Savio"
                width="80px"
              />{" "}
              Savio
            </a>
          </div>
          <div className="links px-2" id="links">

            <a href="/landscaping-services" class="active">
              Landscaping
              Services
            </a>
            <a href="/garden-and-maintenance">Gardening &  Maintenance </a>
            <a href="/land-and-construction-works"> Landscape Construction works</a>
            <a href="/design-and-layout">Design &  Layout</a>
            <a href="/arboriculture">Arboriculture </a>

          </div>

        </div>
      </nav>

    </section>
  );
};

export default Navbar;

import { useState } from "react";

const DesignAndAnalyse = () => {
  const [activeTab, setactiveTab] = useState(0);
  return (
    <section className="container mx-auto py-10">
      <div className="flex items-center justify-center gap-16 md:gap-20 my-20">
        <div className="h-10 w-10 space-y-2" onClick={()=>{setactiveTab(0)}}>
          <img src="/icons/magnifying-glass-solid.svg" alt="" />
          Analyse
         {activeTab===0&&(
             <div className="h-0.5 w-20 bg-[#2a5237]"></div>
         )}
        </div>
        <div className="h-10 w-10 space-y-2 cursor-pointer" onClick={()=>{setactiveTab(1)}}>
          <img src="/icons/lightbulb-solid.svg" alt="" />
          Design
          {activeTab===1&&(
             <div className="h-0.5 w-20 bg-[#2a5237]"></div>
         )}
        </div>

        <div className="h-10 w-10 space-y-2 cursor-pointer" onClick={()=>{setactiveTab(2)}}>
          <img src="/icons/eye-solid.svg" alt="" className="object-contain" />
          Detail
          {activeTab===2&&(
             <div className="h-0.5 w-20 bg-[#2a5237]"></div>
         )}
        </div>
        <div className="h-10 w-10 space-y-2 cursor-pointer" onClick={()=>{setactiveTab(3)}}>
          <img src="/icons/house-solid.svg" alt="" />

          Build
          {activeTab==3&&(
             <div className="h-0.5 w-20 bg-[#2a5237]"></div>
         )}
        </div>
      </div>
      {activeTab == 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-10">
          <div>
            <img src="/images/home/work_stage_1.jpg" alt="" className="w-full object-contain" />
          </div>
          <div className="space-y-8">
            <h1 className="text-[36px]">Work Stage 0-1</h1>
            <p className="text-base px-3 md:px-0">
              We initiate our service by collaborating with clients or project teams to gain a comprehensive understanding of your scheme's requirements. We offer initial evaluations like feasibility studies, landscape visual impact assessments, or reports to facilitate the early identification of both opportunities and constraints.
            </p>
          </div>
        </div>
      )}
        {activeTab == 1 && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-10">
          <div>
            <img src="/images/home/work_stage_2.jpg" alt="" className="object-contain w-full"/>
          </div>
          <div className="space-y-8">
            <h1 className="text-[36px]">Work Stage 0-2</h1>
            <p className="text-base px-3 md:px-0">
            After the design phase, we shift our focus to the detailed planning stage. Our team will meticulously outline all the necessary specifications, measurements, and technical details required to bring the design to life. This involves creating comprehensive drawings, blueprints, and specifications that serve as a blueprint for the construction phase.
            </p>
          </div>
        </div>
      )}
        {activeTab == 2 && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-10">
          <div>
            <img src="/images/home/work_stage_3.jpg" alt="" />
          </div>
          <div className="space-y-8">
            <h1 className="text-[36px]">Work Stage 0-3</h1>
            <p className="text-base px-3 md:px-0">
            After the design phase, we shift our focus to the detailed planning stage. Our team will meticulously outline all the necessary specifications, measurements, and technical details required to bring the design to life. This involves creating comprehensive drawings, blueprints, and specifications that serve as a blueprint for the construction phase.
            </p>
          </div>
        </div>
      )}
        {activeTab == 3 && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-10">
          <div className="image-container" id="work-stage-4-container">
            <img src="/images/home/work_stage_4.webp" alt="worker hands planting seedling" className=""/>
          </div>
          <div className="space-y-8">
            <h1 className="text-[36px]">Work Stage 0-4</h1>
            <p className="text-base px-3 md:px-0">
              With the design and detailed planning in place, we move on to the construction phase. Our skilled and qualified builders and craftsmen will execute the project with utmost precision and attention to detail. From site preparation to material selection, installation of hardscapes and softscapes, and the implementation of irrigation systems, we ensure that every aspect of the project is carried out to the highest standards of quality and craftsmanship.
              <br /> <br />
              Throughout the Design, Detail, and Build process, we maintain open lines of communication with our clients, providing regular updates and seeking their input to ensure their vision is realized. Our goal is to deliver a final result that not only meets but exceeds expectations, creating a beautiful and functional outdoor space that brings joy and satisfaction for years to come.
            </p>
          </div>
        </div>
      )}
    </section>
  );
};

export default DesignAndAnalyse;

import { useState } from "react";

const ValuesContent = () => {

  const tabs = [
    {
      title: "Enhances Curb Appeal",
      desc: "Regular garden maintenance ensures that your outdoor space always looks well-groomed and inviting. Pruning overgrown plants, mowing the lawn, and tidying up flower beds create a visually pleasing landscape that enhances the curb appeal of your property. A well-maintained garden makes a positive first impression and can significantly increase the attractiveness of your home or business."
    },
    {
      title: "Promotes Plant Health",
      desc: "Garden maintenance involves essential practices like fertilizing, watering, and pest control, which are vital for maintaining the health and vitality of your plants. Regular inspections by professional gardeners can identify signs of diseases, nutrient deficiencies, or pest infestations early on, allowing for prompt treatment and prevention of further damage. Proper care ensures that your plants thrive, bloom beautifully, and live longer."
    },
    {
      title: "Weed Control",
      desc: "Weeds not only detract from the visual appeal of a garden but also compete with desirable plants for resources like sunlight, water, and nutrients. Professional garden maintenance includes effective weed control measures such as regular weeding, mulching, and the use of herbicides when necessary. By keeping weeds in check, you create a healthier growing environment for your plants and reduce maintenance efforts in the long run."
    },
    {
      title: "Lawn Care",
      desc: "A lush, green lawn is often the centerpiece of a well-maintained garden. Garden maintenance services typically include lawn care tasks such as mowing, edging, aerating, and fertilizing. These activities help to keep the grass healthy, weed-free, and neatly trimmed, resulting in an attractive and inviting outdoor space for relaxation, play, and social gatherings."
    },
    {
      title: "Seasonal Planting and Pruning",
      desc: "Garden maintenance professionals are well-versed in the specific planting and pruning requirements for different seasons. They can advise you on the best plants to grow during specific times of the year, ensuring that your garden looks vibrant and colorful throughout the seasons. Pruning techniques applied by experts help shape plants, encourage proper growth, and promote abundant blooms."
    },
    {
      title: "Increased Property Value",
      desc: "A well-maintained garden significantly enhances the value of your property. When it comes time to sell, potential buyers are often attracted to homes with beautiful outdoor spaces. A meticulously maintained garden creates a positive impression, showcasing the care and attention to detail given to the entire property. It can ultimately lead to a higher selling price and a faster sale."
    },
    {
      title: "Stress Relief and Well-being",
      desc: "Spending time in a well-maintained garden has proven therapeutic benefits for both physical and mental well-being. A serene outdoor environment provides a peaceful retreat from the demands of everyday life, allowing you to unwind, relax, and connect with nature. Gardening and being surrounded by greenery can reduce stress levels, improve mood, and promote a sense of overall well-being."
    },
    {
      title: "Sustainable Practices",
      desc: "Professional garden maintenance services often prioritize environmentally friendly practices. This includes using organic fertilizers, conserving water through efficient irrigation systems, and implementing sustainable pest control methods. By embracing eco-friendly practices, garden maintenance professionals help create a healthier and more sustainable ecosystem within your outdoor space."
    },
    {
      title: "Time and Effort Savings",
      desc: "Maintaining a garden requires regular time and effort. By outsourcing garden maintenance to professionals, you can save valuable time and enjoy a well-maintained garden without the need for extensive personal involvement. This allows you to focus on other priorities while still reaping the financial benefits."
    }
  ];


    const [active, setactvive] = useState(0);

    return (

      <div className="container mx-auto values-section">

        <div className="grid grid-cols-1 md:grid-cols-2 my-10">

          <div className="space-y-4">

            {tabs.map((tab, index) => (

              <div

                className={

                  index === active

                    ? "tab pl-10 py-3 bg-[#7CC143] text-[#FCFCFC]"

                    : "tab pl-10 py-3 bg-[#d9d9d9] text-black"

                }

                onClick={() => {

                  setactvive(index);

                }}

              >

                {tab.title}

              </div>

            ))}

          </div>

          <div className="bg-[#7CC143] p-10 text-[#FCFCFC] desc">
            {active === 0 && <p>{tabs[active].desc}</p>}
            {<p>{tabs[active].desc}</p>}
          </div>

        </div>

      </div>

    );
  };

  export default  ValuesContent;
import React from 'react';
import { useLocation } from 'react-router-dom';

const Sitemap = () => {
  const location = useLocation();
  const baseUrl = 'https://saviolandscaping.com'; // Replace with your website's base URL

  const routes = [
    '/',
    '/services',
    '/garden-and-maintenance',
    '/land-and-construction-works',
    '/landscaping-services',
    '/design-and-layout',
    '/arboriculture',
    '/projects',
    '/contact',
  ];

  const sitemapXML = `
    <?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${routes
        .map((route) => {
          const url = `${baseUrl}${route}`;
          const isCurrentRoute = location.pathname === route;

          return `
            <url>
              <loc>${url}</loc>
              <changefreq>${isCurrentRoute ? 'daily' : 'weekly'}</changefreq>
            </url>
          `;
        })
        .join('')}
    </urlset>
  `;

  return <pre>{sitemapXML}</pre>;
};

export default Sitemap;

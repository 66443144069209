import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ServicesPage from "./pages/Services";
import LandAndConstruction from "./pages/land-and-construction";
import LandscapServices from "./pages/landscape-services";
import DesignAndLayout from "./pages/design-and-layout";
import Arboriculture from "./pages/arboriculture";
import GardenMaintence from "./pages/garden-maintenance";
import Contact from "./pages/contact";
import Projects from "./pages/projects";
import Sitemap from "./components/sitemap";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/garden-and-maintenance" element={<GardenMaintence />} />

      <Route
        path="/land-and-construction-works"
        element={<LandAndConstruction />}
      />
      <Route path="/landscaping-services" element={<LandscapServices />} />
      <Route path="/design-and-layout" element={<DesignAndLayout />} />
      <Route path="/arboriculture" element={<Arboriculture />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/sitemap.xml" element={<Sitemap />} />
    </Routes>
  </BrowserRouter>
);

reportWebVitals();


const AboutUsSection = () => {

  return (
    <section className="container mx-auto  p-5 lg:p-10 about-section">
      <div className="flex flex-wrap justify-between gap-3  md:flex-nowrap my-8">
        <div className="md:basis-1/2 px-3 py-2 md:px-0 md:py-0">
          <h3>WELCOME TO SAVIO LANDSCAPING</h3>
          <p className="text-base md:text-lg lg:text-start">
          Welcome to Savio Landscaping, where we believe in harnessing the transformative power of nature. Our passion lies in
          enhancing outdoor spaces to improve the quality of life for individuals, families, and communities alike.   <br /> <br />
          At Savio Landscaping Services, we are committed to creating stunning, functional, and sustainable outdoor environments that connect people with nature and enhance their overall well-being.

          </p>
        </div>
        <div className="bg-[#d8df25] text-center p-10 md:basis-1/4 space-y-4">
            <h3>Clients</h3>
            <p className="text-black mb-10">Our goal is to be the preferred provider for all landscape maintenance needs in the eyes of our clients.  </p>
            <a href="/projects"><button className="bg-primary py-4 px-8 text-white w-full mt-4">Our Goal</button></a>
        </div>
        <div className="bg-[#d8d8d8] text-center p-10 md:basis-1/4 space-y-4">
            <h3>Clients</h3>
            <p className="text-black mb-2"> Our objective is to attain recognition from our clients as the top choice for all their landscape maintenance needs.</p>
            <a href="/projects"><button className="bg-primary py-4 px-8 text-white w-full mt-4">Our Objective</button></a>
        </div>
      </div>
    </section>
  );
};
export default AboutUsSection;

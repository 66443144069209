const ShopCategories=()=>{
return (
    <section className="container mx-auto shop-categories">
        <div className="flex flex-wrap gap-y-1 md:flex-nowrap px-3 py-10 md:px-0">
            <div className="bg-primary p-4 w-full md:w-auto h-[200px] flex items-center">
                <h2>
                    Our Shop <br />
                   <strong> Categories</strong>
                </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
               <a href="https://shop.saviolandscaping.com/product-category/plants/">
               <div className="bg-[#f4f4d4] flex items-center justify-center p-4 h-[200px]">
                <h3>PLANTS</h3>
                </div>
               </a>
               <a href="https://shop.saviolandscaping.com/product-category/landscaping-materials/">
               <div className="bg-[#f4f4d4] flex items-center justify-center p-4 h-[200px]">
                <h3>LANDSCAPING MATERIALS</h3>
                </div>
               </a>
               <a href="https://shop.saviolandscaping.com/product-category/landscaping-tools">
               <div className="bg-[#f4f4d4] flex items-center justify-center p-4 h-[200px]">
                <h3>LANDSCAPING TOOLS</h3>
                </div>
               </a>
                <a href="https://shop.saviolandscaping.com/product-category/leasing-equipment/">
                <div className="bg-[#f4f4d4] flex items-center justify-center p-4 h-[200px]">
                <h3>LEASE LANDSCAPING TOOLS</h3>
                </div>
                </a>

            </div>
        </div>
    </section>
)
}

export default ShopCategories
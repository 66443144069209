import { useState } from "react";

const Hero = () => {
  const [activeTab, setactiveTab] = useState(0);

  return (
    <section className="container mx-auto">
      <div className="h-[670px] md:h-[500px] relative">
        {activeTab === 0 && (
          <div
            className="h-full bg-cover bg-center hero-space-top"
            style={{ backgroundImage: "url('../images/home/landscape.jpg')" }}
          >
            <div className=" h-fit w-5/6 mx-auto md:w-[390px] md:absolute md:top-[60px] md:right-[60px] bg-[#7CC143] p-[30px]">
              <h1 className="mt-10 text-white text-3xl md:text-[32px] mb-8">
                <strong>
                  FIRST <br /> CLASS{" "}
                </strong>
                GROUNDS MAINTENCE SERVICES
              </h1>
              <a href="/landscaping-services" className="bg-primary p-4 text-white ">
                DISCOVER MORE
              </a>
            </div>
          </div>
        )}
        {activeTab === 1 && (
          <div
            className="h-full bg-cover bg-center hero-space-top"
            style={{ backgroundImage: "url('../images/home/grounds.jpg')" }}
          >
            <div className="h-fit w-5/6 mx-auto md:w-[390px] md:absolute md:top-[60px] md:right-[60px] bg-[#7CC143] p-[30px]">
              <h1 className="text-white text-[35px] mb-8">
                <strong>
                GARDENING <br />{" "}
                </strong>
                 MAINTENCE SERVICES
              </h1>
              <a href="/garden-and-maintenance" className="bg-primary p-4 text-white ">
                DISCOVER MORE
              </a>
            </div>
          </div>
        )}
                {activeTab === 2 && (
          <div
            className="h-full bg-cover bg-center hero-space-top"
            style={{ backgroundImage: "url('../images/home/arboriculture.jpg')" }}
          >
            <div className="h-fit w-5/6 mx-auto md:w-[390px] md:absolute md:top-[60px] md:right-[60px] bg-[#7CC143] p-[30px]">
              <h1 className="text-white text-[35px] mb-8">
                <strong>
                LANDSCAPE  <br /> {" "}
                </strong>
                CONSTRUCTION WORKS
              </h1>
              <a href="/land-and-construction-works" className="bg-primary p-4 text-white ">
                DISCOVER MORE
              </a>
            </div>
          </div>
        )}
                        {activeTab === 3&& (
          <div
            className="h-full bg-cover bg-center hero-space-top"
            style={{ backgroundImage: "url('../images/home/construction.jpg')" }}
          >
            <div className="h-fit w-5/6 mx-auto md:w-[390px] md:absolute md:top-[60px] md:right-[60px] bg-[#7CC143] p-[30px]">
              <h1 className="text-white text-[35px] mb-8">
                <strong>
                  FIRST <br /> CLASS{" "}
                </strong>
                DESIGN & LAYOUT
              </h1>
              <a href="/design-and-layout" className="bg-primary p-4 text-white ">
                DISCOVER MORE
              </a>
            </div>
          </div>
        )}
                           {activeTab === 4&& (
          <div
            className="h-full bg-cover bg-center hero-space-top"
            style={{ backgroundImage: "url('../images/home/arbori.jpg')" }}
          >
            <div className="h-fit w-5/6 mx-auto md:w-[390px] md:absolute md:top-[60px] md:right-[60px] bg-[#7CC143] p-[30px]">
              <h1 className="text-white text-[35px] mb-8">
                <strong>
                  FIRST CLASS{" "}
                </strong>
                Arboriculture Services
              </h1>
              <a href="/arboriculture" className="bg-primary p-4 text-white ">
                DISCOVER MORE
              </a>
            </div>
          </div>
        )}

        {/* hero section nav links */}
        <div className="grid gap-x-1 gap-y-1 px-2 grid-cols-2 absolute bottom-0 left-0 w-full md:px-0 md:gap-0 md:flex">
          <div
            className="flex flex-col items-center justify-end p-0 cursor-pointer"
            onClick={() => {
              setactiveTab(0);
            }}
          >
            {activeTab === 0 && <div className="triangle-active"></div>}
            <div
              className={
                activeTab === 0
                  ? "bg-[#7cc143] w-full h-[78px] text-white flex items-center justify-center px-9"
                  : "bg-[#28a745] w-full h-[78px] text-white flex items-center justify-center px-9"
              }
            >
              Landscaping Services
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-end p-0 cursor-pointer"
            onClick={() => {
              setactiveTab(1);
            }}
          >
            {activeTab === 1 && <div className="triangle-active"></div>}
            <div
              className={
                activeTab === 1
                  ? "bg-[#7cc143] w-full h-[78px] text-white flex items-center justify-center px-10"
                  : "bg-[#28a745] w-full h-[78px] text-white flex items-center justify-center px-10"
              }
              onClick={() => {
                setactiveTab(2);
              }}
            >
              Gardening & Maintenance
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-end p-0 cursor-pointer"
            onClick={() => {
              setactiveTab(2);
            }}
          >
            {activeTab === 2 && <div className="triangle-active"></div>}
            <div
              className={
                activeTab === 2
                  ? "bg-[#7cc143] w-full h-[78px] text-white flex items-center justify-center px-9"
                  : "bg-[#28a745] w-full h-[78px] text-white flex items-center justify-center px-9"
              }
            >
              Landscape Construction works
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-end p-0 cursor-pointer"
            onClick={() => {
              setactiveTab(3);
            }}
          >
            {activeTab === 3 && <div className="triangle-active"></div>}
            <div
              className={
                activeTab === 3
                  ? "bg-[#7cc143] w-full h-[78px] text-white flex items-center justify-center px-9"
                  : "bg-[#28a745] w-full h-[78px] text-white flex items-center justify-center px-9"
              }
            >
              Design & Layout
            </div>
          </div>
          <div
            className="col-span-2 flex flex-col items-center justify-end p-0 cursor-pointer"
            onClick={() => {
              setactiveTab(4);
            }}
          >
            {activeTab === 4 && <div className="triangle-active"></div>}
            <div
              className={
                activeTab === 4
                  ? " bg-[#7cc143] w-full h-[78px] text-white flex items-center justify-center px-10"
                  : "  bg-[#28a745] w-full h-[78px] text-white flex items-center justify-center px-10"
              }
            >
              Arboriculture Services
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Hero;

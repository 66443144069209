import Footer from "../components/footer";
import Navbar from "../components/navbar";
import ValuesContent from "../components/ValuesContent";

const GardenMaintence = () => {

  return (
    <div>
      <Navbar />
      <div className="container mx-auto">
        <div
          className="bg-cover bg-center h-[400px] relative hero-space-top"
          style={{ backgroundImage: "url('../images/home/landscape.jpg')" }}
        >
          <div className="h-fit w-5/6 mx-auto md:w-[390px] md:absolute md:top-[60px] md:right-[60px] bg-[#7CC143] p-[30px]">
            <h1 className="text-white text-2xl md:text-[35px] mb-8">
              <strong>
                GARDEN & <br />
              </strong>
              MAINTENANCE
            </h1>
            <a href="#offerings" className="bg-primary p-4 text-white ">
              DISCOVER MORE
            </a>
          </div>
        </div>
        <div className="py-10 px-4  bg-[#f7f7f4] md:px-10">
          <p className="text-base">
            While most of the time we spend indoors, after the hectic day work
            most of us yearn to loosen up outside in the sight and sound of
            nature. Indeed nature lends us free aid to soak in the scenic view
            while releasing the stress and embracing the peace. It does not take
            any extra quirk, generates positive feelings while soothing the
            eyes. The list of reasons may go on, then what is withholding you
            from getting your little piece of heaven? <br /> <br />
            Your outdoor space is, thrives, summer solution- a swimming pool or
            other enhancement to your yard, consider the range of benefits you
            can expect from our landscaping service in Sydney. With the changing
            time and constant development in the world of aesthetics, we also
            have changed our technique and ideas. Furthermore, with our more
            than ten years of experience and hard work, we’ve nurtured the
            knowledge to create something extraordinary in your yard. From
            something simple as a yard that needs to be trimmed to an entirely
            modern layout lawn, the Kangaroo Paw gardening & landscaping service
            in Northshore will be there for you. <br /> <br />
            Our team of expert landscapers ing space that resonates with your
            lifestyle and the architecture of your house and to provide you with
            a territory where you can relax, enjoy and celebrate with your
            family & friends.
          </p>
        </div>
        <div className="flex flex-col gap-8 my-10">
          <div className="basis-1/2">
            <img
              src="/images/home/construction.jpg"
              alt=""
              className="h-full"
            />
          </div>
          <div className="basis-1/2 space-y-6 px-4 md:px-6">
            <p className="text-base">
            Garden maintenance services encompass a wide range of tasks and activities aimed at keeping outdoor spaces healthy, vibrant, and visually appealing. These services typically include regular upkeep, care, and improvement of plants, lawns, trees, shrubs, and other elements within a garden or landscape. By entrusting your garden maintenance to professionals, you can enjoy numerous benefits that enhance the beauty, functionality, and overall value of your outdoor space. Let's explore these benefits in detail.
            </p>


          </div>
        </div>
        <section>
          <div className="py-8 px-4 md:px-0" id="offerings">
            <h3 className="text-[40px] mb-3"> What we offer</h3>
            <p className="text-base  mb-3">
              We have solutions for your landscaping needs we provide services
              like:
            </p>
            <ValuesContent />

          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
export default GardenMaintence;

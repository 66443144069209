import Footer from "../components/footer";
import Navbar from "../components/navbar";

const Arboriculture = () => {
  return (
    <div>
      <Navbar />
      <section className="container mx-auto ">
        <div className="flex flex-col md:flex-row gap-0 h-[200px]  items-center my-10 bg-secondary">
          <div className="flex items-center justify-center basis-1/3">
            <h1 className="text-white text-3xl md:text-[40px]">ARBORICULTURE</h1>
          </div>
          <div
            className="h-[200px] w-full basis-2/3 bg-center bg-cover"
            style={{ backgroundImage: "url('../images/home/landscape.jpg')" }}
          ></div>

          {/* <div
            className="bg-cover bg-center h-[100px]"
            style={{ backgroundImage:"url('../images/home/landscape.jpg')" }}
          ></div> */}
        </div>
      </section>
      <section className="container mx-auto">
        <div className="flex flex-col md:flex-row py-10 px-4 md:px-0 md:pl-16">
          <div className="space-y-6 basis-2/3">
            <p>
            Arboriculture is a specialized field within the realm of landscaping that focuses on the care, maintenance, and management of trees. It encompasses a range of services dedicated to the health, safety, and preservation of trees in both urban and natural environments. Arborists, who are trained professionals in arboriculture, provide expertise in tree-related matters and offer various services to ensure the vitality and longevity of trees.
            </p>
            <p>
            The services within arboriculture are crucial for the well-being of trees and the overall landscape. They involve a combination of scientific knowledge, practical skills, and specialized equipment to address tree-related issues effectively. Let's explore some key services within arboriculture:
            </p>

            <ol className="list-none px-4 md:px-0">
              <li className="mb-4"><strong>Tree Pruning and Trimming:</strong> Arborists perform pruning and trimming services to remove dead, diseased, or damaged branches, promote healthy growth, and enhance the overall structure and appearance of trees. Proper pruning techniques help maintain the tree's health, prevent potential hazards, and improve aesthetic appeal.</li>
              <li className="mb-4"><strong>Tree Removal:</strong> In cases where a tree becomes hazardous due to disease, decay, or structural issues, arborists can safely and efficiently remove the tree. They utilize specialized equipment and techniques to ensure the safe extraction of the tree while minimizing any potential risks to property or individuals.</li>
              <li className="mb-4"><strong>Tree Health and Diagnostic Services:</strong> Arborists conduct thorough assessments of tree health, identifying potential diseases, pests, or other issues that may affect the well-being of trees. They provide expert diagnosis and recommend appropriate treatment options to restore or maintain tree health.</li>
              <li className="mb-4"><strong>Tree Planting and Transplanting:</strong> Arborists assist in selecting appropriate tree species for specific environments and planting them correctly to promote successful growth. They also offer tree transplanting services, carefully relocating trees to new locations while minimizing stress and ensuring their survival.</li>
              <img src="/images/home/landscape.jpg" alt="" />
              <li className="mb-4"><strong>Tree Risk Assessment:</strong> Arborists evaluate the structural integrity and potential risks associated with trees. They assess factors such as root stability, branch strength, and overall tree health to identify potential hazards and recommend appropriate risk mitigation measures to ensure the safety of people and property.</li>
              <li className="mb-4"><strong>Tree Preservation and Conservation:</strong> Arborists play a crucial role in preserving and conserving trees in urban and natural landscapes. They implement strategies to protect trees during construction projects, provide recommendations for tree care practices, and educate clients on the value of tree preservation and conservation.</li>
              <li className="mb-4"><strong>Tree Consulting and Expert Witness Services:</strong> Arborists offer professional consulting services, providing expert advice and guidance on tree-related matters. They may also serve as expert witnesses in legal cases, providing specialized knowledge and testimony related to trees and their management.</li>
            </ol>


            <p>
            These arboriculture services are essential for maintaining the health, safety, and beauty of trees in various landscapes. By engaging the expertise of arborists, property owners, municipalities, and other stakeholders can ensure the proper care and management of trees, contributing to the overall environmental sustainability and well-being of the community.
            </p>
          </div>
          <div className="basis-1/3" />
          <div className="flex  flex-col">
            <a href="https://shop.saviolandscaping.com/">
            <div className="bg-primary py-24 px-32">
                <h2>
                    Our Shop <br />
                   <strong> Categories</strong>
                </h2>
            </div>
            </a>
            <a href="https://shop.saviolandscaping.com/product-category/landscaping-materials/">
            <div className="flex flex-col gap-5 ">
              
              <div className="bg-[#f4f4d4] flex items-center justify-center p-24 w-full">
              <h3>SEEDLINGS</h3>
              </div>
              <div className="bg-[#f4f4d4] flex items-center justify-center p-24">
              <h3>FLOWERS</h3>
              </div>
              <div className="bg-[#f4f4d4] flex items-center justify-center p-24">
              <h3>OUTDOOR PLANTS</h3>
              </div>

          </div>
            </a>

        </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Arboriculture;

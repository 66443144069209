import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'

function Projects() {
  return (
    <div>
        <Navbar />
        <section className="container mx-auto ">
            <div className="flex flex-col md:flex-row gap-y-8 h-[200px]  items-center my-10 bg-secondary">
            <div className="flex items-center justify-center basis-1/3 pt-2 ">
                <p className="text-white text-3xl md:text-[40px]">Recent <span className='font-semibold'>Projects</span></p>
            </div>
            <div
                className="h-[200px] w-full basis-2/3 bg-center bg-cover"
                style={{ backgroundImage: "url('../images/home/landscape.jpg')" }}
            ></div>

            </div>
        </section>

        <section className="container mx-auto mb-2">
            {/* <p className='mb-4'>View some of our recently completed projects…</p> */}
            {/* projects section */}
            <div className='grid grid-cols-1 gap-y-6 px-2 md:px-0 md:gap-4  mb-6 md:grid-cols-2 lg:grid-cols-3 '>
                <div
                    className='project-card min-h-[300px] bg-no-repeat'
                    style={{ backgroundImage: "url('../images/projects/project_one.png')" }}
                >
                    <div className='project-info'>
                        <h4>Project 1</h4>
                    </div>
                </div>

                <div
                    className='project-card min-h-[300px] bg-no-repeat'
                    style={{ backgroundImage: "url('../images/projects/project_two.png')" }}
                >
                    <div className='project-info'>
                        <h4>Project 2</h4>
                    </div>
                </div>

                <div
                    className='project-card min-h-[300px]'
                    style={{ backgroundImage: "url('../images/projects/project_three.png')" }}
                >
                    <div className='project-info '>
                        <h4>Project 3</h4>
                    </div>
                </div>

                <div
                    className='project-card min-h-[300px]'
                    style={{ backgroundImage: "url('../images/projects/project_four.png')" }}
                >
                    <div className='project-info '>
                        <h4>Project 4</h4>
                    </div>
                </div>

            </div>
        </section>
        <Footer />
    </div>
  )
}

export default Projects